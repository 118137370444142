import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { DatePicker } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import Signature from '../../../Components/Signature'
import { CloseIcon } from '@chakra-ui/icons'
import { modals } from '../../../Components/Modal'

export default function NurseList({
  errors,
  control,
  setValue,
  watch,
  trigger,
  data,
  register,
  medicalRecordReview,
  clinical,
  allAreas,
  remove,
  index,
}) {
  const [isConfirm, setIsConfirm] = useState(false)
  const onRemove = () => setIsConfirm(true)
  return (
    <>
      {modals.confirm({
        isOpen: isConfirm,
        onClose: () => setIsConfirm(false),
        onSubmit: () => {
          setIsConfirm(false)
          remove(index)
        },
      })}
      {index !== 0 && (
        <hr
          style={{
            backgroundColor: 'black',
            height: 1,
            margin: '16px 0px',
          }}
        />
      )}
      <Box>
        <Center>
          <FormControl
            id="performed"
            style={{
              width: '20%',
              marginBottom: '12px',
            }}
          >
            <FormLabel>Performed by provider</FormLabel>
            <Input
              name={`form.performed`}
              defaultValue={data?.performed || '0'}
              ref={register()}
            />
          </FormControl>
        </Center>
        <Center>
          <FormControl
            id="period"
            isRequired
            isInvalid={errors?.form?.list?.[index]?.period}
            w={'20%'}
            mb={8}
          >
            <FormLabel>Review Period</FormLabel>
            <Controller
              render={props => (
                <DatePicker
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  value={props?.value}
                  picker="quarter"
                  onChange={props.onChange}
                  format={'Y-[Q]Q'}
                />
              )}
              name={`form.list.${index}.period`}
              control={control}
              rules={{ required: 'Period required.' }}
              defaultValue={
                data?.list?.[index]?.period
                  ? moment(data?.list?.[index]?.period)
                  : moment()
              }
            />
            <FormErrorMessage>
              {errors?.form?.list?.[index]?.period &&
                errors?.form?.list?.[index]?.period?.message}
            </FormErrorMessage>
          </FormControl>
        </Center>
        <HStack>
          <FormControl
            id="surgeon"
            isRequired
            isInvalid={errors?.form?.list?.[index]?.surgeon}
          >
            <FormLabel>Surgeon</FormLabel>
            <Input
              name={`form.list.${index}.surgeon`}
              placeholder="Surgeon"
              ref={register({
                required: 'Surgeon required.',
              })}
              defaultValue={data?.list?.[index]?.surgeon ?? ''}
            />
          </FormControl>
          <FormControl
            id="anesthesia"
            isRequired
            isInvalid={errors?.form?.list?.[index]?.anesthesia}
          >
            <FormLabel>Anesthesia Provider</FormLabel>
            <Input
              name={`form.list.${index}.anesthesia`}
              placeholder="Anesthesia Provider"
              ref={register({
                required: 'Anesthesia Provider required.',
              })}
              defaultValue={data?.list?.[index]?.anesthesia ?? ''}
            />
          </FormControl>

          <FormControl
            id="mr"
            isRequired
            isInvalid={errors?.form?.list?.[index]?.mr}
          >
            <FormLabel>MR#</FormLabel>
            <Input
              name={`form.list.${index}.mr`}
              placeholder="MR#"
              ref={register({
                required: 'MR# required.',
              })}
              defaultValue={data?.list?.[index]?.mr ?? ''}
            />
          </FormControl>
          <FormControl isRequired isInvalid={errors?.form?.list?.[index]?.dos}>
            <FormLabel>DOS</FormLabel>
            <Controller
              render={props => (
                <DatePicker
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  value={props?.value}
                  onChange={props?.onChange}
                />
              )}
              name={`form.list.${index}.dos`}
              control={control}
              defaultValue={
                data?.list?.[index]?.dos
                  ? moment(data?.list?.[index]?.dos)
                  : moment()
              }
              rules={{ required: 'DOS is required.' }}
            />
          </FormControl>
        </HStack>
      </Box>

      <Text fontSize="xl" fontWeight="bold" my={6}>
        MEDICAL RECORD REVIEW
      </Text>
      <Box>
        {medicalRecordReview.map((item, qIndex) => (
          <Flex key={qIndex} justifyContent="space-between" my={4}>
            <Text fontSize={18}>{item}</Text>
            <Flex gap={8}>
              <FormControl
                isInvalid={
                  errors?.form?.list?.[index]?.medicalAnswers &&
                  errors?.form?.list?.[index]?.medicalAnswers[qIndex]
                }
              >
                <Controller
                  render={props => (
                    <RadioGroup onChange={props.onChange} value={props.value}>
                      <Stack direction="row">
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                        <Radio value="n/a">N/A</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                  name={`form.list.${index}.medicalAnswers.${qIndex}`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    data?.list?.[index]?.medicalAnswers?.[qIndex] || ''
                  }
                />
              </FormControl>
            </Flex>
          </Flex>
        ))}
      </Box>

      <Text fontSize="xl" fontWeight="bold" my={6}>
        CLINICAL
      </Text>
      <Box>
        {clinical.map((item, qIndex) => (
          <Flex key={qIndex} justifyContent="space-between" my={4}>
            <Text fontSize={18}>{item}</Text>
            <Flex gap={8}>
              <FormControl
                isInvalid={
                  errors?.form?.list?.[index]?.clinicalAnswers &&
                  errors?.form?.list?.[index]?.clinicalAnswers[qIndex]
                }
              >
                <Controller
                  render={props => (
                    <RadioGroup onChange={props.onChange} value={props.value}>
                      <Stack direction="row">
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                        <Radio value="n/a">N/A</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                  name={`form.list.${index}.clinicalAnswers.${qIndex}`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    data?.list?.[index]?.clinicalAnswers?.[qIndex] || ''
                  }
                />
              </FormControl>
            </Flex>
          </Flex>
        ))}
      </Box>

      <Text fontSize="xl" fontWeight="bold" my={6}>
        ALL AREAS
      </Text>
      <Box>
        {allAreas.map((item, qIndex) => (
          <Flex key={qIndex} justifyContent="space-between" my={4}>
            <Text fontSize={18}>{item}</Text>
            <Flex gap={8}>
              <FormControl
                isInvalid={
                  errors?.form?.list?.[index]?.allAreasAnswers &&
                  errors?.form?.list?.[index]?.allAreasAnswers[qIndex]
                }
              >
                <Controller
                  render={props => (
                    <RadioGroup onChange={props.onChange} value={props.value}>
                      <Stack direction="row">
                        <Radio value="yes">Yes</Radio>
                        <Radio value="no">No</Radio>
                        <Radio value="n/a">N/A</Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                  name={`form.list.${index}.allAreasAnswers.${qIndex}`}
                  control={control}
                  rules={{ required: true }}
                  defaultValue={
                    data?.list?.[index]?.allAreasAnswers?.[qIndex] || ''
                  }
                />
              </FormControl>
            </Flex>
          </Flex>
        ))}
      </Box>
      <Box mt={12}>
        <FormControl>
          <Text mb="8px">Comment: </Text>
          <Controller
            render={props => (
              <Textarea
                value={props.value}
                placeholder="Comment"
                onChange={props.onChange}
              />
            )}
            name={`form.list.${index}.comment`}
            control={control}
            defaultValue={data?.list?.[index]?.comment || ''}
          />
        </FormControl>
      </Box>

      <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
        <Box>
          <Controller
            name={`form.list.${index}.signatureCollector`}
            control={control}
            defaultValue={data?.list?.[index]?.signatureCollector || ''}
            render={({ field }) => {
              return (
                <FormControl
                  w="30vw"
                  isInvalid={errors?.form?.list?.[index]?.signatureCollector}
                >
                  <Signature
                    label="Chart Reviewer Signature"
                    field={field}
                    setValue={setValue}
                    watch={watch}
                    data={data?.list?.[index]?.signatureCollector}
                    trigger={trigger}
                    formKey={`form.list.${index}.signatureCollector`}
                  />
                  <FormErrorMessage>
                    {errors?.form?.list?.[index]?.signatureCollector &&
                      errors?.form?.list?.[index]?.signatureCollector.message}
                  </FormErrorMessage>
                </FormControl>
              )
            }}
          />
        </Box>
        <Box display={'flex'} alignItems={'end'}>
          <FormControl isRequired>
            <FormLabel>Date</FormLabel>
            <Controller
              render={props => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  isReadOnly
                  textAlign={'center'}
                />
              )}
              name={`form.list.${index}.date`}
              control={control}
              defaultValue={
                data?.list?.[index]?.date
                  ? moment(data?.list?.[index]?.date).format('YYYY-MM-DD')
                  : moment().format('YYYY-MM-DD')
              }
              rules={{ required: 'Date is required.' }}
            />
          </FormControl>
        </Box>
      </SimpleGrid>
      <Button
        mt={8}
        mb={4}
        variant="outline"
        leftIcon={<CloseIcon />}
        onClick={() => onRemove()}
        colorScheme="red"
      >
        Remove
      </Button>
    </>
  )
}
